<template>
  <div>
    <div class="d-flex justify-content-end mb-2">
        <b-button variant="primary" to="/leads/leads-add">Add Lead</b-button>
    </div>
    <div class="card p-2">
      <div class="kanban-board">
        <div
          v-for="(stage, index) in stages"
          :key="index"
          :class="getStageClass(stage)"
          class="kanban-column text-center p-2"
        >
          <h3>{{ stage }}</h3>
          <draggable
            :list="tasks[stage]"
            group="tasks"
            @end="onDragEnd(stage, $event)"
            class="list-group kanban-column"
            :disabled="stage === 'Won' || stage === 'Lost'"
          >
            <div
              class="list-group-item my-1"
              v-for="task in tasks[stage]"
              :key="task.id"
            >
              {{ task.name }}
              <div>
                <b-form-textarea id="textarea-default" placeholder="Comment" rows="3"
                 />
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BSpinner,
  BContainer,
  BTabs,
  BTab,BFormTextarea
} from "bootstrap-vue";
export default {
  components: {
    draggable,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BContainer,
    BTabs,
    BTab,BFormTextarea
  },
  data() {
    return {
      stages: ["New", "Qualification", "Quotation/Proposal", "Won", "Lost"],
      tasks: {
        New: [
          { id: 1, name: "Lead 1" },
          { id: 2, name: "Lead 2" },
        ],
        Qualification: [],
        "Quotation/Proposal": [],
        Won: [],
        Lost: [],
      },
    };
  },
  methods: {
    onDragEnd(stage, event) {
      // Update task positions after dragging
      this.tasks[stage].splice(0, this.tasks[stage].length, ...event.newList);
    },

    getStageClass(stage) {
      // Define class names based on stage
      return {
        "alert alert-secondary": stage === "New",
        "alert alert-warning": stage === "Qualification",
        "alert alert-info": stage === "Quotation/Proposal",
        "alert alert-primary": stage === "Won",
        "alert alert-danger": stage === "Lost",
      };
    },
  },
};
</script>

<style>
.kanban-board {
  display: flex;
  justify-content: space-around;
}

.kanban-column {
  flex: 1;
  margin: 0 10px;
}

.kanban-column {
  min-height: 300px;
}
</style>
